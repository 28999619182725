import React, { useCallback, useRef, useState } from 'react';
import {
  useFloating,
  flip,
  shift,
  autoUpdate,
  type Placement,
  offset,
} from '@floating-ui/react-dom';
import { Transition } from '@headlessui/react';
import classNames from 'classnames';
import { useDocumentEventListener } from '../../hooks';
import { BoltIcon } from '../../icons/small';
import { Key } from '../../utils/events';
import { Link } from '../atoms/Link';

export interface TooltipProps {
  content: React.ReactNode;
  children: React.ReactNode;
  disabled?: boolean;
  onShow?: () => void;
  onHide?: () => void;
  childrenClassName?: string;
  pointer?: boolean;
  tabable?: boolean;
  placement?: Placement;
  disabledContent?: React.ReactNode;
  // advanced tooltip props
  title?: string;
  icon?: React.ReactNode;
  link?: string;
  linkLabel?: string;
}

export function Tooltip({
  content,
  children,
  disabled = false,
  onShow,
  onHide,
  childrenClassName,
  pointer = true,
  tabable = true,
  placement = 'top',
  disabledContent,
  title,
  icon,
  link,
  linkLabel,
}: TooltipProps): JSX.Element {
  const [isActive, setIsActive] = useState(false);
  const [escHit, setEscHit] = useState(false);
  const appearTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);

  const { floatingStyles, refs } = useFloating({
    open: isActive,
    placement,
    middleware: [offset(8), flip(), shift({ padding: 16 })],
    whileElementsMounted: autoUpdate,
  });

  const handleFocus = () => {
    if (appearTimeout.current) clearTimeout(appearTimeout.current);
    appearTimeout.current = setTimeout(() => {
      setIsActive(true);
    }, 0);
  };

  const handleBlur = () => {
    if (appearTimeout.current) clearTimeout(appearTimeout.current);
    setEscHit(false);
    setIsActive(false);
  };

  const listener = useCallback(
    (event: KeyboardEvent) => {
      if (isActive && event.key === Key.Escape) {
        setEscHit(true);
      }
    },
    [isActive],
  );
  useDocumentEventListener('keydown', listener);
  return (
    <div onPointerOver={handleFocus} onPointerEnter={handleFocus} onPointerLeave={handleBlur}>
      <div
        tabIndex={tabable ? 0 : -1}
        role="button"
        onFocus={handleFocus}
        onBlur={handleBlur}
        className={classNames(
          !disabled && pointer ? 'cursor-default' : 'cursor-default',
          childrenClassName,
        )}
        aria-disabled={disabled}
        ref={refs.setReference}
      >
        {children}
      </div>

      <Transition
        appear
        show={isActive && !escHit && (!disabled || Boolean(disabledContent))}
        role="tooltip"
        as="div"
        className="z-[900] transition-opacity"
        enter="ease-out duration-300"
        enterFrom="opacity-0 z-20"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        beforeEnter={onShow}
        afterLeave={onHide}
        ref={refs.setFloating}
        style={floatingStyles}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex max-w-[306px] flex-col gap-y-2 rounded-md border border-cf-gray-5 bg-cf-gray-4 p-2 font-aeonikRegular">
          {title && (
            <>
              <div
                className={classNames(
                  `flex items-center gap-x-1 font-aeonikMedium text-14 text-white`,
                )}
              >
                {icon || <BoltIcon />}
                <span>Title</span>
              </div>
              <div className="w-full border-b border-b-[#2B2B2B]" />
            </>
          )}
          <div className={classNames('flex items-center justify-center text-12 text-cf-light-3')}>
            {disabled ? disabledContent : content}
          </div>
          {link && (
            <Link href={link} color="green" className="text-12" target="_blank">
              {linkLabel || 'Learn more'}
            </Link>
          )}
        </div>
      </Transition>
    </div>
  );
}
