export { default as AlarmIcon } from './flip-ui-kit/large/Alarm';
export { default as AlarmWarningIcon } from './flip-ui-kit/large/AlarmWarning';
export { default as AlertIcon } from './flip-ui-kit/large/Alert';
export { default as AnnotationDotsIcon } from './flip-ui-kit/large/AnnotationDots';
export { default as AnnotationIcon } from './flip-ui-kit/large/Annotation';
export { default as AnnotationWarningIcon } from './flip-ui-kit/large/AnnotationWarning';
export { default as AnnouncementIcon } from './flip-ui-kit/large/Announcement';
export { default as ArrowBackIcon } from './flip-ui-kit/large/ArrowBack';
export { default as ArrowIcon } from './flip-ui-kit/large/Arrow';
export { default as AwardIcon } from './flip-ui-kit/large/Award';
export { default as BadgeIcon } from './flip-ui-kit/large/Badge';
export { default as BankNoteIcon } from './flip-ui-kit/large/BankNote';
export { default as BellIcon } from './flip-ui-kit/large/Bell';
export { default as BlockOutsideIcon } from './flip-ui-kit/large/BlockOutside';
export { default as BoltIcon } from './flip-ui-kit/large/Bolt';
export { default as BookIcon } from './flip-ui-kit/large/Book';
export { default as BookmarkIcon } from './flip-ui-kit/large/Bookmark';
export { default as BooksIcon } from './flip-ui-kit/large/Books';
export { default as BoxIcon } from './flip-ui-kit/large/Box';
export { default as BridgeIcon } from './flip-ui-kit/large/Bridge';
export { default as BrokerIcon } from './flip-ui-kit/large/Broker';
export { default as BugIcon } from './flip-ui-kit/large/Bug';
export { default as CalendarIcon } from './flip-ui-kit/large/Calendar';
export { default as ChartCheckIcon } from './flip-ui-kit/large/ChartCheck';
export { default as ChartIcon } from './flip-ui-kit/large/Chart';
export { default as ChatIcon } from './flip-ui-kit/large/Chat';
export { default as CheckCircleIcon } from './flip-ui-kit/large/CheckCircle';
export { default as CheckmarkIcon } from './flip-ui-kit/large/Checkmark';
export { default as ChevronIcon } from './flip-ui-kit/large/Chevron';
export { default as ClipboardFailIcon } from './flip-ui-kit/large/ClipboardFail';
export { default as ClipboardIcon } from './flip-ui-kit/large/Clipboard';
export { default as ClockIcon } from './flip-ui-kit/large/Clock';
export { default as CloseCircleIcon } from './flip-ui-kit/large/CloseCircle';
export { default as ClosedBookIcon } from './flip-ui-kit/large/ClosedBook';
export { default as CloseIcon } from './flip-ui-kit/large/Close';
export { default as CodeIcon } from './flip-ui-kit/large/Code';
export { default as CoinsIcon } from './flip-ui-kit/large/Coins';
export { default as CompressIcon } from './flip-ui-kit/large/Compress';
export { default as ConstructionIcon } from './flip-ui-kit/large/Construction';
export { default as ContractCallIcon } from './flip-ui-kit/large/ContractCall';
export { default as ContractFailIcon } from './flip-ui-kit/large/ContractFail';
export { default as ContractSuccessIcon } from './flip-ui-kit/large/ContractSuccess';
export { default as CopyIcon } from './flip-ui-kit/large/Copy';
export { default as CrowdIcon } from './flip-ui-kit/large/Crowd';
export { default as CrownIcon } from './flip-ui-kit/large/Crown';
export { default as DashboardIcon } from './flip-ui-kit/large/Dashboard';
export { default as DiscordIcon } from './flip-ui-kit/large/Discord';
export { default as DistanceIcon } from './flip-ui-kit/large/Distance';
export { default as DocIcon } from './flip-ui-kit/large/Doc';
export { default as Dollar2Icon } from './flip-ui-kit/large/Dollar2';
export { default as DollarIcon } from './flip-ui-kit/large/Dollar';
export { default as DotsHorizontalIcon } from './flip-ui-kit/large/DotsHorizontal';
export { default as DotsIcon } from './flip-ui-kit/large/Dots';
export { default as DotsVerticalIcon } from './flip-ui-kit/large/DotsVertical';
export { default as DownloadIcon } from './flip-ui-kit/large/Download';
export { default as DropletIcon } from './flip-ui-kit/large/Droplet';
export { default as EditIcon } from './flip-ui-kit/large/Edit';
export { default as EducationIcon } from './flip-ui-kit/large/Education';
export { default as EmojiHappyIcon } from './flip-ui-kit/large/EmojiHappy';
export { default as EmojiNeutralIcon } from './flip-ui-kit/large/EmojiNeutral';
export { default as EmojiSadIcon } from './flip-ui-kit/large/EmojiSad';
export { default as EthereumIcon } from './flip-ui-kit/large/Ethereum';
export { default as ExperimentIcon } from './flip-ui-kit/large/Experiment';
export { default as EyeIcon } from './flip-ui-kit/large/Eye';
export { default as EyeHideIcon } from './flip-ui-kit/large/EyeHide';
export { default as FeeIcon } from './flip-ui-kit/large/Fee';
export { default as FileIcon } from './flip-ui-kit/large/File';
export { default as FileTextIcon } from './flip-ui-kit/large/FileText';
export { default as FilterIcon } from './flip-ui-kit/large/Filter';
export { default as FingerSwipeIcon } from './flip-ui-kit/large/FingerSwipe';
export { default as FireIcon } from './flip-ui-kit/large/Fire';
export { default as FlagIcon } from './flip-ui-kit/large/Flag';
export { default as FlareIcon } from './flip-ui-kit/large/Flare';
export { default as FloppyDiscIcon } from './flip-ui-kit/large/FloppyDisc';
export { default as ForbidIcon } from './flip-ui-kit/large/Forbid';
export { default as GasIcon } from './flip-ui-kit/large/Gas';
export { default as GavelIcon } from './flip-ui-kit/large/Gavel';
export { default as GiftIcon } from './flip-ui-kit/large/Gift';
export { default as GithubIcon } from './flip-ui-kit/large/Github';
export { default as GlobeIcon } from './flip-ui-kit/large/Globe';
export { default as GridIcon } from './flip-ui-kit/large/Grid';
export { default as HamburgerIcon } from './flip-ui-kit/large/Hamburger';
export { default as HeartIcon } from './flip-ui-kit/large/Heart';
export { default as HelpCircleIcon } from './flip-ui-kit/large/HelpCircle';
export { default as HelpIcon } from './flip-ui-kit/large/Help';
export { default as HomeIcon } from './flip-ui-kit/large/Home';
export { default as HourglassIcon } from './flip-ui-kit/large/Hourglass';
export { default as IceIcon } from './flip-ui-kit/large/Ice';
export { default as InformationIcon } from './flip-ui-kit/large/Information';
export { default as KeyholeIcon } from './flip-ui-kit/large/Keyhole';
export { default as KeyIcon } from './flip-ui-kit/large/Key';
export { default as KnifeIcon } from './flip-ui-kit/large/Knife';
export { default as LayersIcon } from './flip-ui-kit/large/Layers';
export { default as LightBulbIcon } from './flip-ui-kit/large/LightBulb';
export { default as LinkIcon } from './flip-ui-kit/large/Link';
export { default as LockIcon } from './flip-ui-kit/large/Lock';
export { default as LockUnlockedIcon } from './flip-ui-kit/large/LockUnlocked';
export { default as MaximizeIcon } from './flip-ui-kit/large/Maximize';
export { default as MenuIcon } from './flip-ui-kit/large/Menu';
export { default as MessageIcon } from './flip-ui-kit/large/Message';
export { default as MinimizeIcon } from './flip-ui-kit/large/Minimize';
export { default as MinusCircleIcon } from './flip-ui-kit/large/MinusCircle';
export { default as MinusIcon } from './flip-ui-kit/large/Minus';
export { default as MoneyBagIcon } from './flip-ui-kit/large/MoneyBag';
export { default as MoveIcon } from './flip-ui-kit/large/Move';
export { default as NodeIcon } from './flip-ui-kit/large/Node';
export { default as NoSearchIcon } from './flip-ui-kit/large/NoSearch';
export { default as PageFlipIcon } from './flip-ui-kit/large/PageFlip';
export { default as PaperclipIcon } from './flip-ui-kit/large/Paperclip';
export { default as PartyIcon } from './flip-ui-kit/large/Party';
export { default as PencilIcon } from './flip-ui-kit/large/Pencil';
export { default as PercentageIcon } from './flip-ui-kit/large/Percentage';
export { default as PiggybankIcon } from './flip-ui-kit/large/Piggybank';
export { default as PiggyIcon } from './flip-ui-kit/large/Piggy';
export { default as PinTackIcon } from './flip-ui-kit/large/PinTack';
export { default as PlusCircleIcon } from './flip-ui-kit/large/PlusCircle';
export { default as PlusIcon } from './flip-ui-kit/large/Plus';
export { default as PowerIcon } from './flip-ui-kit/large/Power';
export { default as PulseIcon } from './flip-ui-kit/large/Pulse';
export { default as PuzzleIcon } from './flip-ui-kit/large/Puzzle';
export { default as QRIcon } from './flip-ui-kit/large/QR';
export { default as ReceiptIcon } from './flip-ui-kit/large/Receipt';
export { default as RedditIcon } from './flip-ui-kit/large/Reddit';
export { default as Refresh2Icon } from './flip-ui-kit/large/Refresh2';
export { default as RefreshIcon } from './flip-ui-kit/large/Refresh';
export { default as RightDoubleIcon } from './flip-ui-kit/large/RIghtDouble';
export { default as RobotIcon } from './flip-ui-kit/large/Robot';
export { default as RocketIcon } from './flip-ui-kit/large/Rocket';
export { default as RotateIcon } from './flip-ui-kit/large/Rotate';
export { default as RouteIcon } from './flip-ui-kit/large/Route';
export { default as RssFeedIcon } from './flip-ui-kit/large/RssFeed';
export { default as ScaleIcon } from './flip-ui-kit/large/Scale';
export { default as ScrollIcon } from './flip-ui-kit/large/Scroll';
export { default as SearchIcon } from './flip-ui-kit/large/Search';
export { default as SendIcon } from './flip-ui-kit/large/Send';
export { default as Settings2Icon } from './flip-ui-kit/large/Settings2';
export { default as SettingsIcon } from './flip-ui-kit/large/Settings';
export { default as SettingsSlidersIcon } from './flip-ui-kit/large/SettingsSliders';
export { default as ShieldIcon } from './flip-ui-kit/large/Shield';
export { default as ShieldTickIcon } from './flip-ui-kit/large/ShieldTick';
export { default as ShieldWarningIcon } from './flip-ui-kit/large/ShieldWarning';
export { default as ShipmentCheckIcon } from './flip-ui-kit/large/ShipmentCheck';
export { default as ShuffleIcon } from './flip-ui-kit/large/Shuffle';
export { default as SlantedArrowIcon } from './flip-ui-kit/large/SlantedArrow';
export { default as SleepClockIcon } from './flip-ui-kit/large/SleepClock';
export { default as SortIcon } from './flip-ui-kit/large/Sort';
export { default as SparklesIcon } from './flip-ui-kit/large/Sparkles';
export { default as SpeechBubbleIcon } from './flip-ui-kit/large/SpeechBubble';
export { default as SpinnerIcon } from './flip-ui-kit/large/Spinner';
export { default as StarIcon } from './flip-ui-kit/large/Star';
export { default as SwapIcon } from './flip-ui-kit/large/Swap';
export { default as Switch2Icon } from './flip-ui-kit/large/Switch2';
export { default as SwitchIcon } from './flip-ui-kit/large/Switch';
export { default as TagIcon } from './flip-ui-kit/large/Tag';
export { default as TargetIcon } from './flip-ui-kit/large/Target';
export { default as TelegramIcon } from './flip-ui-kit/large/Telegram';
export { default as ThumbsUpIcon } from './flip-ui-kit/large/ThumbsUp';
export { default as TimePreviousIcon } from './flip-ui-kit/large/TimePrevious';
export { default as TimerIcon } from './flip-ui-kit/large/Timer';
export { default as TrashIcon } from './flip-ui-kit/large/Trash';
export { default as TrendingDownIcon } from './flip-ui-kit/large/TrendingDown';
export { default as TrendingUpIcon } from './flip-ui-kit/large/TrendingUp';
export { default as TrophyIcon } from './flip-ui-kit/large/Trophy';
export { default as TwitterIcon } from './flip-ui-kit/large/Twitter';
export { default as UserCheckIcon } from './flip-ui-kit/large/UserCheck';
export { default as UserIcon } from './flip-ui-kit/large/User';
export { default as UsersIcon } from './flip-ui-kit/large/Users';
export { default as WalletIcon } from './flip-ui-kit/large/Wallet';
export { default as WarningTriangleIcon } from './flip-ui-kit/large/WarningTriangle';
export { default as WindowIcon } from './flip-ui-kit/large/Window';
export { default as WithdrawIcon } from './flip-ui-kit/large/Withdraw';
export { default as WriteNoteIcon } from './flip-ui-kit/large/WriteNote';
export { default as TimeBoost } from './flip-ui-kit/large/TimeBoost';
export { default as SliceIcon } from './flip-ui-kit/large/Slice';
export { default as RefundIcon } from './flip-ui-kit/large/Refund';
export { default as TimeSpeedIcon } from './flip-ui-kit/large/TimeSpeed';
export { default as ChunksIcon } from './flip-ui-kit/large/Chunks';
export { default as VolumeIcon } from './flip-ui-kit/large/Volume';
