import { Fragment, type ReactNode } from 'react';
import { Dialog, Transition } from '@headlessui/react';

interface ModalProps {
  open: boolean;
  children: ReactNode;
  onClose: () => void;
  initialFocus?: React.RefObject<HTMLElement>;
  afterLeave?: () => void;
}

export const DashboardModal = ({
  open,
  children,
  onClose,
  initialFocus,
  afterLeave,
}: ModalProps): JSX.Element => (
  <Transition appear show={open} as={Fragment} afterLeave={afterLeave}>
    <Dialog
      onClose={onClose}
      as="div"
      className="fixed inset-0 z-50 overflow-y-auto"
      initialFocus={initialFocus}
    >
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="fixed inset-0 backdrop-blur-sm" />
      </Transition.Child>

      <div className="flex h-full w-full flex-col items-center justify-center">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Dialog.Panel as="div">
            <Dialog.Title />
            <Dialog.Description />
            {children}
          </Dialog.Panel>
        </Transition.Child>
      </div>
    </Dialog>
  </Transition>
);
