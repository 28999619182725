import { type ChainflipAsset } from '@chainflip/utils/chainflip';
import { formatUsdValue } from '@chainflip/utils/number';
import { type DefaultRawDatum, type PieTooltipProps, ResponsivePie } from '@nivo/pie';
import classNames from 'classnames';
import LoadingSpinner from '@/shared/components/LoadingSpinner';
import QuestionMarkTooltip from '@/shared/components/QuestionMarkTooltip';
import { useBoostPool } from '@/shared/hooks/useBoostPool';
import { SliceIcon } from '@/shared/icons/small';
import { TokenAmount } from '@/shared/utils';
import { SkeletonLine } from '../atoms/LoadingSkeleton';
import { ChartTooltip } from '../ChartTooltip';
import Pill from '../flip-ui-kit/Pill';

const LiquidityBlock = ({
  type,
  asset,
  amount,
  amountUsd,
  percentage,
}: {
  type: 'available' | 'in use';
  asset: ChainflipAsset;
  amount: TokenAmount;
  amountUsd: string;
  percentage: number;
}) => (
  <div className="flex flex-col gap-y-1">
    <div className="flex items-center gap-x-2">
      <div
        className={`h-[11px] rounded-md border-[1.5px] ${
          type === 'available' ? 'border-cf-green-1' : 'border-cf-orange-2'
        }`}
      />
      <span className="font-aeonikMedium text-16 text-white">
        {amount.toPreciseFixedDisplay()} {asset.toUpperCase()}
      </span>
      <span className="text-14 text-cf-light-2">{type}</span>
    </div>
    <div className="flex items-center gap-x-0.5 font-aeonikMedium text-12 text-cf-light-2">
      <span>{formatUsdValue(amountUsd)}</span>
      <span>({percentage.toFixed(2)}%)</span>
    </div>
  </div>
);

export const PieChartTooltip = (props: PieTooltipProps<DefaultRawDatum>): JSX.Element => (
  <ChartTooltip>
    <div className="flex w-[170px] items-center justify-between gap-x-2 text-14">
      <span className="text-cf-light-2">
        <div className="flex items-center gap-x-1.5 font-aeonikMedium">
          <div
            className="mb-px h-2.5 w-[3px] rounded-md"
            style={{
              backgroundColor: props.datum.color,
            }}
          />
          {props.datum.label}:
        </div>
      </span>
      <span className="text-cf-white">{props.datum.value.toFixed(2)}%</span>
    </div>
  </ChartTooltip>
);

const Chart = ({ data }: { data: (DefaultRawDatum & { color: string })[] }) => (
  <div className="h-[124px] w-[124px] md:h-[140px] md:w-[140px]">
    <ResponsivePie
      fit
      data={data}
      margin={{ top: 1, right: 0, bottom: 1, left: 0 }}
      colors={(d) => d.data.color}
      innerRadius={0.6}
      tooltip={PieChartTooltip}
      enableArcLabels={false}
      enableArcLinkLabels={false}
      borderWidth={2}
      borderColor={{
        from: 'color',
        modifiers: [['brighter', 0.1]],
      }}
      defs={[
        {
          id: 'poolBoostLiquidityBreakdownChart',
          type: 'linearGradient',
          colors: [
            { offset: 0, color: 'inherit', opacity: 1 },
            {
              offset: 100,
              color: 'inherit',
              opacity: 0.8,
            },
          ],
        },
      ]}
      fill={[{ match: '*', id: 'poolBoostLiquidityBreakdownChart' }]}
      animate={false}
    />
  </div>
);

export const BoostPoolLiquidityBreakdownChart = ({
  asset,
  feeBps,
  account,
  className,
}: {
  asset: ChainflipAsset;
  feeBps: number;
  account?: string;
  className?: string;
}) => {
  const { boostPoolOverview: poolOverview, boostBalances, isLoading } = useBoostPool(asset, feeBps);

  const accountBalance = boostBalances?.find((balance) => balance.lpIdSs58 === account);

  const balance = account ? accountBalance : poolOverview;

  const availableAmount = balance?.availableAmount ?? '0';
  const unavailableAmount = balance?.unavailableAmount ?? '0';
  const avalableAmountValueUsd = balance?.availableAmountValueUsd ?? '0';
  const unavailableAmountValueUsd = balance?.unavailableAmountValueUsd ?? '0';

  const availableLiquidityTokenAmount = TokenAmount.fromAsset(availableAmount, asset);
  const unavailableLiquidityTokenAmount = TokenAmount.fromAsset(unavailableAmount, asset);

  const totalBoostLiquidity = availableLiquidityTokenAmount.add(unavailableLiquidityTokenAmount);
  const availablePercentage = availableLiquidityTokenAmount
    .ratio(totalBoostLiquidity)
    .multipliedBy(100);
  const unavailablePercentage = unavailableLiquidityTokenAmount
    .ratio(totalBoostLiquidity)
    .multipliedBy(100);

  const chartData = [
    {
      id: 'Available',
      value: availablePercentage.toNumber(),
      color: '#46DA93',
    },
    {
      id: 'In-use',
      value: unavailablePercentage.toNumber(),
      color: '#FFD2A8',
    },
  ].filter((d) => d.value > 0);

  return (
    <div
      className={classNames(
        'cf-card flex h-full min-h-[260px] flex-col items-center gap-y-4',
        className,
      )}
    >
      <div className="relative flex w-full justify-between self-start font-aeonikMedium text-14 text-cf-light-2">
        <div className="flex items-center gap-x-2">
          <span>Current Liquidity Status</span>
          <QuestionMarkTooltip content="A detailed view of the current liquidity available to boost deposits in this pool and fee tier" />
        </div>
        {account && (
          <div className="font-aeonikMedium text-12 text-white">
            {isLoading ? (
              <SkeletonLine />
            ) : (
              <div className="absolute right-0 top-0">
                <Pill
                  iconColor="text-white"
                  color="neutral"
                  text={`${(accountBalance?.poolShare ?? 0).toFixed(2)}% share`}
                  Icon={SliceIcon}
                />
              </div>
            )}
          </div>
        )}
      </div>
      {!isLoading ? (
        <div className="flex w-full flex-col items-center justify-between gap-y-8 sm:flex-row">
          <div className="flex flex-col gap-y-4">
            <LiquidityBlock
              type="available"
              asset={asset}
              amount={availableLiquidityTokenAmount}
              amountUsd={avalableAmountValueUsd}
              percentage={availablePercentage.toNumber() || 0}
            />
            <div className="border-b border-cf-gray-4" />
            <LiquidityBlock
              type="in use"
              asset={asset}
              amount={unavailableLiquidityTokenAmount}
              amountUsd={unavailableAmountValueUsd}
              percentage={unavailablePercentage.toNumber() || 0}
            />
          </div>
          <div>
            <Chart data={chartData} />
          </div>
        </div>
      ) : (
        <div className="flex h-full w-full items-center justify-center">
          <LoadingSpinner />
        </div>
      )}
    </div>
  );
};
