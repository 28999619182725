export const enum LiquidityProvisionEvents {
  // onboarding events
  CtaSignIn = 'cta_sign_in',
  SignInSuccess = 'sign_in_success',
  CtaConnectWallet = 'cta_connect_wallet',
  CtaConfirmFunding = 'cta_confirm_funding',
  ConfirmFundingSuccess = 'confirm_funding_success',
  CtaConfirmRegistration = 'cta_confirm_registration',
  ConfirmRegistrationSuceess = 'confirm_registration_success',
  CtaSaveRefundAddress = 'cta_save_refund_address',
  SaveRefundAddressSuccess = 'save_refund_address_success',

  // deposit events
  DepositAsset = 'deposit_asset',
  OpenDepositChannel = 'open_deposit_channel',
  OpenDepositChannelSuccess = 'open_deposit_channel_success',
  PendingDeposit = 'pending_deposit',
  DepositSuccess = 'deposit_success',

  // position events
  AddPosition = 'add_position',
  CtaPlaceLimitOrder = 'cta_place_limit_order',
  CtaPlaceRangeOrder = 'cta_place_range_order',
  CtaConfirmLimitOrder = 'cta_confirm_limit_order',
  CtaConfirmRangeOrder = 'cta_confirm_range_order',
  ConfirmLimitOrderSuccess = 'confirm_limit_order_success',
  ConfirmRangeOrderSuccess = 'confirm_range_order_success',
  CloseLimitOrder = 'close_limit_order',
  CloseRangeOrder = 'close_range_order',
  CloseLimitOrderSuccess = 'close_limit_order_success',
  CloseRangeOrderSuccess = 'close_range_order_success',

  // boost events
  CtaAddBoostLiquidity = 'cta_add_boost_liquidity',
  CtaRemoveBoostLiquidity = 'cta_remove_boost_liquidity',
  ConfirmRemoveBoostLiquidity = 'confirm_remove_boost_liquidity',
  AddBoostLiquiditySuccess = 'add_boost_liquidity_success',
  RemoveBoostLiquiditySuccess = 'remove_boost_liquidity_success',
}
export type LiquidityProvisionTrackEvents = {
  [LiquidityProvisionEvents.CtaSignIn]: {
    path: string;
  };
  [LiquidityProvisionEvents.SignInSuccess]: {
    path: string;
    walletProvider: string;
    scAccount: string;
    scAccountName: string;
    // balance: string;
  };
  [LiquidityProvisionEvents.CtaConnectWallet]: {
    path: string;
    walletProvider: string;
    scAccount: string;
  };
  [LiquidityProvisionEvents.CtaConfirmFunding]: {
    path: string;
    // walletProvider: string;
    scAccount: string;
    fundingAmount: string;
  };
  [LiquidityProvisionEvents.ConfirmFundingSuccess]: {
    path: string;
    // walletProvider: string;
    scAccount: string;
    fundingAmount: string;
  };
  [LiquidityProvisionEvents.CtaConfirmRegistration]: {
    path: string;
    scAccount: string;
    scRole: string;
  };
  [LiquidityProvisionEvents.ConfirmRegistrationSuceess]: {
    path: string;
    scAccount: string;
    scRole: string;
  };
  [LiquidityProvisionEvents.CtaSaveRefundAddress]: {
    path: string;
    scAccount: string;
    refundAddress: string;
  };
  [LiquidityProvisionEvents.SaveRefundAddressSuccess]: {
    path: string;
    scAccount: string;
    refundAddress: string;
  };
  [LiquidityProvisionEvents.DepositAsset]: {
    path: string;
    scAccount: string;
    position: string;
  };
  [LiquidityProvisionEvents.OpenDepositChannel]: {
    path: string;
    scAccount: string;
    position: string;
    asset: string;
    boostFeeBps: number;
  };
  [LiquidityProvisionEvents.OpenDepositChannelSuccess]: {
    path: string;
    scAccount: string;
    position: string;
    asset: string;
    depositAddress: string;
    boostFeeBps: number;
    // timestamp: string;
    // expirationTime: string;
  };
  [LiquidityProvisionEvents.PendingDeposit]: {
    path: string;
    scAccount: string;
    position: string;
    asset: string;
    depositAddress: string;
    depositAmount: string;
  };
  [LiquidityProvisionEvents.DepositSuccess]: {
    path: string;
    scAccount: string;
    position: string;
    asset: string;
    depositAddress: string;
    depositAmount: string;
  };
  [LiquidityProvisionEvents.AddPosition]: {
    path: string;
    scAccount: string;
    position: string;
  };
  [LiquidityProvisionEvents.CtaPlaceLimitOrder]: {
    path: string;
    scAccount: string;
    position: string;
    type: string;
    side: string;
    price: string;
    amount: string;
    collectableFees: string;
  };
  [LiquidityProvisionEvents.CtaConfirmLimitOrder]: {
    path: string;
    scAccount: string;
    position: string;
    type: string;
    side: string;
    price: string;
    amount: string;
    collectableFees: string;
  };
  [LiquidityProvisionEvents.ConfirmLimitOrderSuccess]: {
    path: string;
    scAccount: string;
    position: string;
    type: string;
    side: string;
    price: string;
    amount: string;
    collectableFees: string;
  };
  [LiquidityProvisionEvents.CloseLimitOrder]: {
    path: string;
    scAccount: string;
    position: string;
    type: string;
    side: string;
    price: string;
    amount: string;
    collectableFees: string;
    fillPercentage: number;
  };
  [LiquidityProvisionEvents.CloseLimitOrderSuccess]: {
    path: string;
    scAccount: string;
    position: string;
    type: string;
    side: string;
    price: string;
    amount: string;
    collectableFees: string;
    fillPercentage: number;
  };
  [LiquidityProvisionEvents.CtaPlaceRangeOrder]: {
    path: string;
    scAccount: string;
    position: string;
    type: string;
    lowPrice: string;
    highPrice: string;
    amountBaseAsset: string;
    amountQuoteAsset: string;
  };
  [LiquidityProvisionEvents.CtaConfirmRangeOrder]: {
    path: string;
    scAccount: string;
    position: string;
    type: string;
    lowPrice: string;
    highPrice: string;
    amountBaseAsset: string;
    amountQuoteAsset: string;
  };
  [LiquidityProvisionEvents.ConfirmRangeOrderSuccess]: {
    path: string;
    scAccount: string;
    position: string;
    type: string;
    lowPrice: string;
    highPrice: string;
    amountBaseAsset: string;
    amountQuoteAsset: string;
  };
  [LiquidityProvisionEvents.CloseRangeOrder]: {
    path: string;
    scAccount: string;
    position: string;
    type: string;
    lowPrice: string;
    highPrice: string;
    amountBaseAsset: string;
    amountQuoteAsset: string;
    collectableFeeBaseAsset: string;
    collectableFeeQuoteAsset: string;
  };
  [LiquidityProvisionEvents.CloseRangeOrderSuccess]: {
    path: string;
    scAccount: string;
    position: string;
    type: string;
    lowPrice: string;
    highPrice: string;
    amountBaseAsset: string;
    amountQuoteAsset: string;
    collectableFeeBaseAsset: string;
    collectableFeeQuoteAsset: string;
  };
  [LiquidityProvisionEvents.CtaAddBoostLiquidity]: {
    path: string;
    position: 'my_boost_position';
    scAccount: string;
    feeTierBps: number;
    amount: string;
    asset: string;
    poolShare: number;
    projectedPoolShare: number;
    estApy: string;
  };
  [LiquidityProvisionEvents.CtaRemoveBoostLiquidity]: {
    path: string;
    position: 'my_boost_position';
    scAccount: string;
    feeTierBps: number;
  };
  [LiquidityProvisionEvents.ConfirmRemoveBoostLiquidity]: {
    path: string;
    position: 'my_boost_position';
    scAccount: string;
    feeTierBps: number;
    amount: string;
    asset: string;
    poolShare: number;
    earnedFees: string;
  };
  [LiquidityProvisionEvents.AddBoostLiquiditySuccess]: {
    path: string;
    position: 'my_boost_position';
    scAccount: string;
    feeTierBps: number;
    amount: string;
    asset: string;
    poolShare: number;
  };
  [LiquidityProvisionEvents.RemoveBoostLiquiditySuccess]: {
    path: string;
    position: 'my_boost_position';
    scAccount: string;
    feeTierBps: number;
    amount: string;
    asset: string;
    poolShare: number;
    earnedFees: string;
  };
};
